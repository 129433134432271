import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText } from 'src/interface/basics/sections';

const WorkDetailArticle = ({ work = {}, className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-up')}>
      <section id='article' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row>
            <Col md={4}>
              <SectionHeader>
                <SectionTitle>The Project</SectionTitle>
              </SectionHeader>
            </Col>
            <Col md={8}>
              <SectionBody>
                <div className='article'>{ReactHtmlParser(work.article.html)}</div>
              </SectionBody>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default WorkDetailArticle;
