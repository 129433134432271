import React from 'react';
import { FadIcon } from 'src/interface/vendors/fa-wrapper';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';

const IconArticle = ({ photo, icon, title, className = '', children }) => {

	return (
		<article className={`icon-article ${className}`}>
			{photo && <LazyImg src={photo} alt={title + ' icon photo'} className='icon-photo'/>}
			{icon && <FadIcon icon={icon} className='icon-svg primary'/>}
			{title && <h5 className='ml-4 light gray-800'>{title}</h5>}
		</article>
	)
}

export default IconArticle;
