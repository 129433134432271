import React from 'react';
import { Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, CardFooter } from 'src/interface/basics/cards';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { ExternalLink } from 'src/interface/basics/links';
import { FadIcon, FalIcon } from 'src/interface/vendors/fa-wrapper';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';

const IconCard = ({ icon, photo, title, description, link, className = '', children }) => {

	return (
		<Card className={`icon-card ${className}`}>
			<Row noGutters>
				<Col sm={3}>
					<div className='icon-icon'>
						{photo && <LazyImg src={photo} alt={title + ' photo'} height={80}/>}
						{icon && <FadIcon icon={icon} size='2x' className='primary' />}
					</div>
				</Col>
				<Col sm={9} className=''>
					<CardBody className='icon-card-body flex-between'>
						{title && <CardTitle className='no-m gray-800'>{title}</CardTitle>}
						{link && <ExternalLink href={link} className='btn btn-text-gray'><FalIcon icon='external-link'/></ExternalLink>}
						{children}
					</CardBody>
				</Col>
			</Row>
		</Card>
	)
}

export default IconCard;
