import React from 'react';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Header, HeaderBg, HeaderBody, HeaderPretitle, HeaderTitle, HeaderSubtitle, HeaderText, HeaderBtns } from 'src/interface/basics/headers';
import { ExternalLink } from 'src/interface/basics/links';
import { FabIcon, FalIcon } from 'src/interface/vendors/fa-wrapper';

const WorkDetailHeader = ({ work = {} }) => {

  return (
    <Header className=''>
      <HeaderBg background={work.photo && work.photo.url} className='mvh-50'>
        <Container className='z-20' fluid='md'>
          <Row>
            <Col lg={8}>
              <HeaderBody className='animate fade-in-up delay-200'>
                <HeaderPretitle className='primary'>Case Study</HeaderPretitle>
                <HeaderTitle>{work.title}</HeaderTitle>
                <HeaderSubtitle>{work.subtitle}</HeaderSubtitle>
                <HeaderText>{work.description}</HeaderText>
                <HeaderBtns className='animate fade-in-up delay-400'>
                   {(!work.webUrl && !work.appStoreUrl && !work.playStoreUrl) && (
                    <ExternalLink href={work.webUrl} className='btn btn-opaque-white btn-lg'>
                      <FalIcon icon='store-slash' className='mr-3'/>Not Available
                    </ExternalLink>
                  )}
                  {work.webUrl && (
                    <ExternalLink href={work.webUrl} className='btn btn-opaque-white btn-lg'>
                      <FalIcon icon='globe' className='mr-3'/>Website
                    </ExternalLink>
                  )}
                  {work.appStoreUrl && (
                    <ExternalLink href={work.appStoreUrl} className='btn btn-opaque-white btn-lg ml-3'>
                      <FabIcon icon='apple' className='mr-3'/>App Store
                    </ExternalLink>
                  )}
                  {work.playStoreUrl && (
                    <ExternalLink href={work.playStoreUrl} className='btn btn-opaque-white btn-lg ml-3'>
                      <FabIcon icon='google' className='mr-3'/>Play Store
                    </ExternalLink>
                  )}
                </HeaderBtns>
              </HeaderBody>
            </Col>
          </Row>
        </Container>
      </HeaderBg>
    </Header>
  )
}

export default WorkDetailHeader;
