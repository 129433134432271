import React, { useState } from 'react';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import IconArticle from 'src/interface/articles/icon-article';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText } from 'src/interface/basics/sections';
import { FadIcon } from 'src/interface/vendors/fa-wrapper';

const WorkDetailFeatures = ({ features = [], className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-up')}>
      <section id='features' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row>
            <Col md={4}>
              <SectionHeader>
                <SectionTitle>Notable Features</SectionTitle>
              </SectionHeader>
            </Col>
            <Col md={8}>
              <SectionBody>
                <Row>
                  {features.map(feature => (
                    <Col key={feature.id} sm={12} md={6}>
                      <IconArticle icon='check' title={feature.title} className='mb-5' />
                    </Col>
                  ))}
                </Row>
              </SectionBody>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default WorkDetailFeatures;
