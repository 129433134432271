import React, { useState } from 'react';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText } from 'src/interface/basics/sections';
import { PrevNavCard, NextNavCard } from 'src/interface/cards/nav-card';

const WorkDetailNav = ({ work = {}, works = [], className = '' }) => {
  const [ animation, setAnimation ] = useState('');
  const prevWork = works.find(w => work.position == 1 ? w.position == works.length : w.position == (work.position - 1));
  const nextWork = works.find(w => work.position == works.length ? w.position == 1 : w.position == (work.position + 1));

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-up')}>
      <section id='nav' className={`py-8 animate ${animation} ${className}`}>
        <Container className='text-left'>
          <SectionHeader>
            <SectionTitle>Keep Reading</SectionTitle>
          </SectionHeader>
          <SectionBody className='pt-5'>
            <Row>
              <Col lg={6}>
                <PrevNavCard
                  to={`/work/${prevWork.slug}`}
                  photo={prevWork.photo && prevWork.photo.url}
                  title={prevWork.title}>
                </PrevNavCard>
              </Col>
              <Col lg={6}>
                <NextNavCard
                  to={`/work/${nextWork.slug}`}
                  photo={nextWork.photo && nextWork.photo.url}
                  title={nextWork.title}>
                </NextNavCard>
              </Col>
            </Row>
          </SectionBody>
        </Container>
      </section>
    </Waypoint>
  )
}

export default WorkDetailNav;
