import React from 'react';
import RbBreadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'gatsby';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';

const Breadcrumb = ({ className = '', children }) => (
  <RbBreadcrumb className={className}>
    {children}
  </RbBreadcrumb>
)

const BreadcrumbItem = ({ to, active, className = '', children }) => (
  <div className={`breadcrumb-item ${className}`}>
    {children}
  </div>
)

const BreadcrumbLink = ({ to, active, className = '', children }) => (
	<div className='flex-start'>
	  <Link to={to} className={`link breadcrumb-link ${className}`}>
	    {children}
	  </Link>
	  {!active && <FalIcon icon='chevron-right' className='gray-400 mx-2' />}
  </div>
)

export { Breadcrumb, BreadcrumbItem, BreadcrumbLink }
