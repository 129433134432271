import React, { useState } from 'react';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import IconCard from 'src/interface/cards/icon-card';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText } from 'src/interface/basics/sections';

const WorkDetailTech = ({ tech = [], className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-up')}>
      <section id='tech' className={`py-8 animate ${animation} ${className}`}>
        <Container className='text-left'>
          <Row>
            <Col md={4}>
              <SectionHeader>
                <SectionTitle>Tech Stack</SectionTitle>
              </SectionHeader>
            </Col>
            <Col md={8}>
              <SectionBody>
                <Row>
                  {tech.length > 0 && tech.map(t => (
                    <Col key={t.id} md={6}>
                      <IconCard
                        photo={t.photo && t.photo.url}
                        title={t.title}
                        link={t.link}
                        className='mb-5'
                      />
                    </Col>
                  ))}
                </Row>
              </SectionBody>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default WorkDetailTech;
