import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from 'src/interface/basics/breadcrumbs';
import { dashToString } from 'src/utils/transforms';

const MktBreadcrumbs = (props) => {
  const { history, className } = props;
  const [ breadcrumbs, setBreadcrumbs ] = useState([]);
  const location = useLocation();


  // Hooks
  useEffect(() => {
    setBreadcrumbs(location.pathname.split('/'));
  }, []);


  // Render
  return (
    <Breadcrumb>
      {breadcrumbs.map((breadcrumb, index) => {
        let active = breadcrumbs.length == (index + 1);
        let filteredBreadcrumbs = breadcrumbs.filter((b, newIndex) => newIndex <= index);
        let link = filteredBreadcrumbs.join('/');
        if (index == 0) return null;
        return (
          <BreadcrumbLink key={index} to={link} active={active} className={active ? 'link-gray-300' : 'link-primary'}>
            {dashToString(breadcrumb)}
          </BreadcrumbLink>
        )
      })}
    </Breadcrumb>
  )
}

export default MktBreadcrumbs;
