import React from 'react';
import { Link } from 'gatsby';
import { Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, CardFooter } from 'src/interface/basics/cards';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';

const PrevNavCard = ({ to, photo, title, subtitle, description, className = '', children }) => {

	return (
		<Link to={to}>
			<Card className={`nav-card ${className}`}>
				<CardImg src={photo} className='nav-card-img'/>
				<CardBody className='nav-card-body'>
					{title && <CardTitle className='gray-800 mb-2'>{title}</CardTitle>}
					{subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
					{description && <CardText>{description}</CardText>}
					<div className='link link-primary'>
						<span><FalIcon icon='long-arrow-left' className='mr-2'/>Prev</span>
					</div>
				</CardBody>
			</Card>
		</Link>
	)
}


const NextNavCard = ({ to, photo, title, subtitle, description, className = '', children }) => {

	return (
		<Link to={to}>
			<Card className={`nav-card ${className}`}>
				<CardImg src={photo} className='nav-card-img'/>
				<CardBody className='nav-card-body text-right'>
					{title && <CardTitle className='gray-800 mb-2'>{title}</CardTitle>}
					{subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
					{description && <CardText>{description}</CardText>}
					<div className='link link-primary'>
						<span>Next<FalIcon icon='long-arrow-right' className='ml-2'/></span>
					</div>
				</CardBody>
			</Card>
		</Link>
	)
}

export { PrevNavCard, NextNavCard };
