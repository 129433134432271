import React, { Fragment, useState } from 'react';
import { Button, ButtonWrapper } from 'src/interface/basics/buttons';
import { Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, CardFooter } from 'src/interface/basics/cards';
import { Modal, ModalHeader, ModalImg, ModalBody, ModalFooter } from 'src/interface/basics/modals';
import { FadIcon } from 'src/interface/vendors/fa-wrapper';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';

const PhotoCard = ({ photo, pretitle, title, subtitle, description, animation, className = '', children }) => {
	const [ modal, setModal ] = useState(false);

	return (
		<Fragment>
			<Modal show={modal} toggleModal={() => setModal(!modal)}>
				<ModalHeader className='bg-gray-50' />
				<ModalImg src={photo} className='bg-gray-50 px-5'/>
				<ModalBody className='p-5'>
					<h6 className='pretitle mb-2'>{subtitle}</h6>
					<h4 className='title mb-2'>{title}</h4>
					{/*<h6 className='text'>{description}</h6>*/}
				</ModalBody>
			</Modal>
			<Card className={`photo-card ${modal ? 'active' : ''}`}>
				<ButtonWrapper onClick={() => setModal(!modal)}>
					<LazyImg src={photo} alt={title + ' photo'} height={300} />
				</ButtonWrapper>
			</Card>
		</Fragment>
	)
}

export default PhotoCard;
