import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import WorkDetailHeader from 'src/components/work/detail/work-detail-header';
import WorkDetailArticle from 'src/components/work/detail/work-detail-article';
import WorkDetailFeatures from 'src/components/work/detail/work-detail-features';
import WorkDetailTech from 'src/components/work/detail/work-detail-tech';
import WorkDetailPhotos from 'src/components/work/detail/work-detail-photos';
import WorkDetailTestimonial from 'src/components/work/detail/work-detail-testimonial';
import WorkDetailNav from 'src/components/work/detail/work-detail-nav';

import MktBreadcrumbs from 'src/interface/breadcrumbs/mkt-breadcrumbs';
import MktRoute from 'src/interface/routes/mkt-route';
import MktNav from 'src/interface/navs/mkt-nav';
import MktPrefooter from 'src/interface/prefooters/mkt-prefooter';
import MktFooter from 'src/interface/footers/mkt-footer';

const WorkDetailPage = (props) => {
  const work = props.data.graphCmsPortfolio;
  const works = props.data.allGraphCmsPortfolio.nodes;


  // Hooks
  useEffect(() => {
    console.log('work: ', work);
    console.log('works: ', works);
  }, [])


  // Render
  return (
    <MktRoute title={`Mile Hi Labs | Our Work | ${work.title}`} description={work.description}>
      <MktNav />
      <MktBreadcrumbs />
      <WorkDetailHeader work={work} />
      <main className='relative mvh-50'>
        <WorkDetailArticle work={work} className='bg-white'/>
        <WorkDetailTech tech={work.portfolioTech} className='bg-gray-50'/>
        <WorkDetailFeatures features={work.portfolioFeatures} className='bg-white'/>
        <WorkDetailPhotos work={work} photos={work.portfolioPhotos} className='bg-gray-50'/>
        {/*<WorkDetailTestimonial work={work} testimonial={work.portfolioTestimonial} className='bg-white'/>*/}
        <WorkDetailNav work={work} works={works} className='bg-white'/>
        <MktPrefooter />
      </main>
      <MktFooter />
    </MktRoute>
  )
}

export const query = graphql`
  query($id: String!) {
    graphCmsPortfolio(id: { eq:  $id }) {
      id
      photo { url }
      title
      subtitle
      description
      article { html }
      slug
      position
      webUrl
      appStoreUrl
      playStoreUrl
      portfolioFeatures {
        id
        icon
        title
        description
      }
      portfolioTech {
        id
        photo { url }
        title
        description
        link
      }
      portfolioPhotos {
        id
        photo { url }
        title
        platform
        description
      }
      portfolioTestimonial {
        id
        photo { url }
        quote
        title
        subtitle
        company
      }
    }
    allGraphCmsPortfolio {
      nodes {
        id
        photo { url }
        title
        subtitle
        description
        slug
        position
      }
    }
  }
`

export default WorkDetailPage;
