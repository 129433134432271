import React from 'react';
import RbModal from 'react-bootstrap/Modal';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';

const Modal = ({ show = false, size = 'lg', animation = true, centered = false, scrollable = true, toggleModal, className = '', children }) => (
  <RbModal show={show} size={size} animation={animation} centered={centered} scrollable={scrollable} onHide={toggleModal} className={className}>
    {children}
  </RbModal>
)

const ModalHeader = ({ title, toggleModal, className = '', children } ) => (
	<RbModal.Header closeButton={true} onHide={toggleModal} className={className + ' flex-between'}>
		{title && <h4 className='title'>{title}</h4>}
		{children}
	</RbModal.Header>
)

const ModalImg = ({ src, alt = '', className = '', children }) => (
	<div className={`modal-img ${className}`}>
		<img src={src} alt={alt} height={300} />
	</div>
)

const ModalBody = ({ className = '', children }) => (
	<RbModal.Body className={className}>
		{children}
	</RbModal.Body>
)

const ModalFooter = ({ className = '', children }) => (
  <RbModal.Footer className={className}>
    {children}
  </RbModal.Footer>
)

export { Modal, ModalHeader, ModalImg, ModalBody, ModalFooter }

// Docs
// https://react-bootstrap.github.io/components/modals
