import React, { useState } from 'react';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import PhotoCard from 'src/interface/cards/photo-card';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText } from 'src/interface/basics/sections';

const WorkDetailPhotos = ({ work = {}, photos = [], className = '' }) => {
  const [ animation, setAnimation ] = useState('');
  const webFirst = photos.filter(p => p.platform == 'web').slice(0, 2);
  const webSecond = photos.filter(p => p.platform == 'web').slice(2, 4);
  const webThird = photos.filter(p => p.platform == 'web').slice(4, 6);
  const iosFirst = photos.filter(p => p.platform == 'ios').slice(0, 3);
  const iosSecond = photos.filter(p => p.platform == 'ios').slice(3, 6);


  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-up')}>
      <section id='gallery' className={`py-8 animate ${animation} ${className}`}>
        <Container className='text-left'>
          <SectionHeader>
            <SectionTitle>Photo Gallery</SectionTitle>
          </SectionHeader>
          <SectionBody className='pt-5'>
            <Row>
              {webFirst.map((photo, index) => (
                <Col key={photo.id} sm={6} lg={6}>
                  <PhotoCard
                    photo={photo.photo && photo.photo.url}
                    title={photo.title}
                    subtitle={work.title}
                    description={photo.description}
                    className='mb-4' />
                </Col>
              ))}
              {iosFirst.map((photo, index) => (
                <Col key={photo.id} sm={6} lg={4}>
                  <PhotoCard
                    photo={photo.photo && photo.photo.url}
                    title={photo.title}
                    subtitle={work.title}
                    description={photo.description}
                    className='mb-4' />
                </Col>
              ))}
              {webSecond.map((photo, index) => (
                <Col key={photo.id} sm={6} lg={6}>
                  <PhotoCard
                    photo={photo.photo && photo.photo.url}
                    title={photo.title}
                    subtitle={work.title}
                    description={photo.description}
                    className='mb-4' />
                </Col>
              ))}
              {iosSecond.map((photo, index) => (
                <Col key={photo.id} sm={6} lg={4}>
                  <PhotoCard
                    photo={photo.photo && photo.photo.url}
                    title={photo.title}
                    subtitle={work.title}
                    description={photo.description}
                    className='mb-4' />
                </Col>
              ))}
              {webThird.map((photo, index) => (
                <Col key={photo.id} sm={6} lg={6}>
                  <PhotoCard
                    photo={photo.photo && photo.photo.url}
                    title={photo.title}
                    subtitle={work.title}
                    description={photo.description}
                    className='mb-4' />
                </Col>
              ))}
            </Row>
          </SectionBody>
        </Container>
      </section>
    </Waypoint>
  )
}

export default WorkDetailPhotos;
